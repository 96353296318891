<template>
	<div :title="selectedName">
		<span class="sr-only">Making a selection will cause content on this page to update.</span>
		<label class="sr-only" style="display:block" for="measurement">Measurement</label>
		<div :class="[multipleOptions ? 'select' : 'one_option select']">
			<select id="measurement" v-model="selected">
				<option v-for="option in metricUnits" v-bind:value="option.id">{{ option.name }}</option>
			</select>
            <div class="select-styled-container"><div class="select-styled">{{selectedName}} <SelectIcon /></div></div>
		</div>
	</div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex';
import URL from '@/util/url';
import SelectIcon from "@/components/utils/SelectIcon.vue";

export default {
	data: function () {
        return {

        }
	},
	mounted() {
		//this.getData();
	},
	computed: {
		...mapGetters([
			'metricUnits',
			'metricUnit'
		]),
		multipleOptions: function () {
			if (!this.metricUnits) {
				return;
			}

			if (!Array.isArray(this.metricUnits)) {
				return;
			}

			if (this.metricUnits.length <= 1) {
				return;
			}

			return true;

		},
		selectedName: function () {

			if (!this.metricUnits) {
				return;
			}


			let match = _.find(this.metricUnits, {id: this.selected});

			if (!match) {
				return _.get(_.first(this.metricUnits), "name");
			}

			return match.name;
		},
		selected: {
			get() {
				return this.metricUnit.id;
			},
			set(value) {
				this.setMetricUnitState(_.find(this.metricUnits, {id: value}));
			}
		}
	},
	methods: {
		...mapActions(
				[
					'setMetricUnitState'
				]
		)
	},
	watch: {
		metricUnits: function () {

			let match = _.find(this.metricUnits, {id: URL.hash.unit});

			if (match) {
				this.setMetricUnitState(match);
				return;
			}

			this.setMetricUnitState(_.first(this.metricUnits));
		}
	},
	components: {SelectIcon}
}
</script>
