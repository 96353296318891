<template>
    <h4>{{title}}</h4>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    computed : {
        ...mapGetters([
            'application',
            'applications',
            'technology',
            'technologies',
            'periods',
            'period',

        ]),
        selectedPeriod : function(){
            if(!this.periods || _.isEmpty(this.periods)){
                return;
            }

            if(!this.period){
                return;
            }

            let match = _.find(this.periods, {id: this.period});

            if(_.isEmpty(match)){
                //console.log(this.periods[0])
                return this.periods[0];

            }

            return match;
        },
        isSingleApplicationSelected : function(){
            if(this.applications){
                if(this.application){
                    let selection = _.find(this.applications, {id : this.application});
                    if(!_.isEmpty(selection)){
                        if(selection.hasOwnProperty('name')){
                            if(_.trim(_.lowerCase(selection.name)) !== "all applications"){
                                return true;
                            }
                        }
                    }
                }
            }
        },
        isAllApplicationsSelected : function(){
            if(this.applications){
                if(this.application){
                    let selection = _.find(this.applications, {id : this.application});
                    if(!_.isEmpty(selection)){
                        if(selection.hasOwnProperty('name')){
                            if(_.trim(_.lowerCase(selection.name)) == "all applications"){
                                return true;
                            }
                        }
                    }
                }
            }
        },
        tense : function(){
             let tense = 'present';
             if(this.selectedPeriod){
                if(this.selectedPeriod.hasOwnProperty('name')){
                    if(_.trim(_.lowerCase(this.selectedPeriod.name)) !== "this year"){
                        tense = 'past';
                    }
                }
            }
            return tense;
        },

        tenseHelp: function(){

            //all applications in present tense use "help save"
            //all applications in past tense tense use "helped save"
            if(this.isAllApplicationsSelected){
                if(this.tense == 'present'){
                    return 'help';
                }
                if(this.tense == 'past'){
                    return 'helped';
                }
            }

            //single applications in present tense use "helps save"
            //single applications in past tense use "helped save"
            if(this.isSingleApplicationSelected){
                if(this.tense == 'present'){
                    return 'helps';
                }
                if(this.tense == 'past'){
                    return 'helped';
                }
            }
        },
        title : function(){
            let title;

            if(!this.technologies || _.isEmpty(this.technologies)){
                return;
            }

            if(!this.technology || _.isEmpty(this.technology)){
                return;
            }

            //stolen mostly from from react
            let dataTitle = _.find(this.technologies, {id : this.technology});

            if(!dataTitle){
                return;
            }

            if(!dataTitle.hasOwnProperty('name')){
                return;
            }

            let all = dataTitle['name'].includes("All");

            let tense = this.tenseHelp;

            if(!tense){
                tense = "help";
            }

            // FYI on what 8IksMehrdm is
            // "id": "8IksMehrdm",
            // "name": "All Technologies",
            // "sortOrder": 0

            if (this.technology == "8IksMehrdm") {
                title = 'All of our technologies combined';
            } else if (all) {
                title = `${dataTitle.name} technologies combined`;
            } else {
                title = `Our ${dataTitle.name}`;
            }

            return `${title} ${tense}\u00a0save`;
        }
    }
}
</script>