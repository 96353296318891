<template>
    <div :title="selectedName">
        <span class="sr-only">Making a selection will cause content on this page to update.</span>
        <label class="sr-only" style="display:block" for="timeframe">Timeframe</label>
        <div :class="[multipleOptions ? 'select' : 'one_option select']">
            <select id="timeframe" v-model="selected">
                <option v-for="option in periods" v-bind:value="option.id">{{option.name}}</option>
            </select>
            <div class="select-styled-container"><div class="select-styled">{{selectedName}} <SelectIcon /></div></div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import URL from '@/util/url';
import SelectIcon from "@/components/utils/SelectIcon.vue";

export default {
     data : function () {
        return {

        }
    },
    mounted(){
        //this.getData();
    },
    computed : {
        ...mapGetters([
            'periods',
            'period',
        ]),
        multipleOptions : function(){
            if(!this.periods){
                return;
            }

            if(! Array.isArray(this.periods)){
                return;
            }

            if(this.periods.length <= 1){
                return;
            }

            return true;

        },
        selectedName :function() {

            if(!this.periods){
                return;
            }

            let match = _.first(_.filter(this.periods, { id :this.selected }));

            if(!match){
              return _.get(_.first(this.periods), "name");
            }

            return match.name;
        },
        selected : {
            get () {
                return this.period;
            },
            set (value) {
                this.setPeriodState(value);
            }
        }
    },
    methods : {
        ...mapActions(
            [
                'setPeriodState'
            ]
        )
    },
    watch : {
        periods : function(){
            let match = _.find(this.periods, { id : parseInt(URL.hash.period) });

            if(match){
                this.setPeriodState(match.id);
                return;
            }

            this.setPeriodState( _.get(_.first(this.periods), "id") );

        }
    },
		components: {SelectIcon}
}
</script>
