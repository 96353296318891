<template>
    <div>
        <h1>
            <div class="d-flex align-items-center justify-content-center h-100">
                <span v-for="number in rollingNumbers" :key="number.i" :class="number.class" >{{number.value}}</span>
            </div>
        </h1>
        <h2 class="d-none">
            {{detail}}
        </h2>
        <button @click="toggle" class="pause" :class="{paused: timer}">{{ buttonText }}</button>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Formatting from "@/util/formatting.js";
export default {
    data : function () {
        return {
            timer : false,
            buttonText : "Pause"
        }
    },
    destroyed:function(){
        clearInterval(this.timer)
        this.timer = false;
        this.buttonText = "Pause";
    },
    mounted() {
        this.play();
    },
    methods : {
        ...mapActions([
            'getCounterInterval',
            'getFactoidInterval'
        ]),
        toggle : function(){
            if(this.timer){
                clearInterval(this.timer)
                this.timer = false;
                this.buttonText = "Play";
            } else {
                this.play();
                this.buttonText = "Pause";
            }
        },
        play : function(){
            this.timer = setInterval( () =>{
                this.getCounterInterval();
                this.getFactoidInterval();
            }, 50);
        }
    },
    computed : {
        ...mapGetters([
            'runRate',
            'count',
        ]),
        rollingNumbers : function(){
       // let fm = (new Intl.NumberFormat('zh-Hant-CN-u-nu-hanidec', {
       //   style: 'decimal',
       //   useGrouping: true
       // }).format(_.ceil(this.count)));
         //   console.log(fm)
         //   return Formatting.htmlizeNumbers(fm);
            return Formatting.htmlizeNumbers(Formatting.commaSeparateNumber(_.round(this.count)));
        },
        detail : function(){
            if(this.count > 999){
                return Formatting.numberToText(this.count);
            }
        }
    }
}
</script>