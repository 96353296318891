<template>
    <div class="impact_select">
        <span class="sr-only">Making a selection will cause content on this page to update.</span>
        <label for="impact" class="sr-only" style="display:block">Impact</label>
        <div :class="[multipleOptions ? 'select' : 'one_option select']">
           <select id="impact" v-model="selected">
                <option v-for="option in impactAreas" v-bind:value="option.id">{{option.name}}</option>
            </select>
           <div class="select-styled-container"><div class="select-styled">{{selectedName}} <SelectIcon /></div></div>
        </div>
        <div id="icon" :class="sluggedClassName"></div>
        <img src="@/img/water.svg" alt="water" class="d-none" />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import URL from '@/util/url';
import SelectIcon from "@/components/utils/SelectIcon.vue";

export default {
     data : function () {
        return {
        }
    },
    mounted(){
        //this.getData();
    },
    computed : {
        ...mapGetters([
            'impactAreas',
            'impactArea',
        ]),
        sluggedClassName : function(){
            if(!this.impactAreas){
                return;
            }

            let match = _.first(_.filter(this.impactAreas, { id : this.selected }));

            if(!match){
              return _.get(_.first(this.impactAreas), "name");
            }

            //console.log(match)

            return match.name.replace(" ", "-");
        },
        multipleOptions : function(){
            if(!this.impactAreas){
                return;
            }

            if(! Array.isArray(this.impactAreas)){
                return;
            }

            if(this.impactAreas.length <= 1){
                return;
            }

            return true;

        },
        selectedName :function() {

            if(!this.impactAreas){
                return;
            }

            let match = _.first(_.filter(this.impactAreas, { id : this.selected }));

            if(!match){
              return _.get(_.first(this.impactAreas), "name");
            }

            return match.name;
        },
        selected : {
            get () {
                return this.impactArea;
            },
            set (value) {
                this.setImpactAreaState(value);
            }
        }
    },
    methods : {
        ...mapActions(
            [
                'setImpactAreaState'
            ]
        )
    },
    watch : {
        impactAreas : function(){

            let match = _.find(this.impactAreas, { id : URL.hash.impactArea });
            if(match){
                 this.setImpactAreaState(match.id);
                 return;
            }

            this.setImpactAreaState( _.get(_.first(this.impactAreas), "id") );

        }
    },
	components: {SelectIcon}
}
</script>
