<template>
	<div class="live_counter" :class="photoClass" id="nav-live-counter" role="tabpanel" aria-labelledby="nav-live-counter-tab" tabindex="0">
		<backgrounds v-if="!widget"></backgrounds>
		<div class="content">
			<div class="row h-100 flex-column flex-md-row" v-if="!widget">
				<div class="col-md-4 col-xl-3">
					<div v-show="internalView" class="mobile_change_application d-md-none">
						<button class="d-md-none" @click="showOverlay('applicationSelector')">Change Application</button>
					</div>
					<aside class="d-none d-md-block">
						<div class="aside_border d-flex align-items-center">
							<div v-show="internalView" class="w-100">
								<button class="d-md-none" @click="showOverlay('applicationSelector')">Change Application</button>
								<application-selector class="d-none d-md-block"></application-selector>
							</div>
							<div v-show="!internalView">
								<h2>What is the Live{{ '\u00a0' }}Counter?</h2>
								<p>Tracks water, energy, waste and greenhouse gas benefits delivered to customers through key Ecolab{{ '\u00a0' }}technologies.</p>
							</div>
						</div>
					</aside>
				</div>
				<div class="col-md-8 col-xl-9 flex-fill">
					<section class="count">
						<div v-show="!records.length" class="get_started h-100">
							<div class="d-none">
								<h3>Choose an application and technology to start{{ '\u00a0' }}counter</h3>
							</div>
						</div>
						<div v-show="records.length" class="border_lg">
							<div class="w-100">
								<counter-title></counter-title>
								<div id="numberContainer">
									<numbers></numbers>
								</div>
								<div class="inputs row justify-content-center less_gutter">
									<div class="col-auto d-flex align-items-center units">
										<metric-select></metric-select>
									</div>
									<div class="col-auto d-flex align-items-center of non_input">
										<span>of</span>
									</div>
									<div class="col-auto d-flex align-items-center resource">
										<impact-select></impact-select>
									</div>
									<div class="col-12 col-xs-auto d-flex align-items-center time">
										<period-select></period-select>
									</div>
								</div>
								<div class="dynamic_factoid">
									<dynamic-factoid></dynamic-factoid>
								</div>
							</div>
						</div>

					</section>
				</div>
			</div>
			<section class="h-100 count" v-if="widget && records.length">
				<div class="border_lg">
					<div class="w-100">
						<counter-title></counter-title>
						<div id="numberContainer">
							<numbers></numbers>
						</div>

						<!-- not selectable, but included so that the code can execute -->
						<!-- these selections need to be made for the tool to work -->
						<!-- they will default to an auto selection or use URL params -->
						<div class="d-none">
							<metric-select></metric-select>
							<impact-select></impact-select>
							<period-select></period-select>
						</div>

						<h2 class="text-lowercase mb-0">
							{{ selectedMetric }} of {{ selectedImpact }} {{ selectedPeriod }}
						</h2>
						<div class="dynamic_factoid">
							<dynamic-factoid></dynamic-factoid>
						</div>
					</div>
				</div>

				<a :href="fullScreenLink" class="widget_fullscreen_link" target="_blank" aria-label="Fullscreen Mode">Fullscreen</a>
			</section>
		</div>
	</div>
</template>

<script>
import applicationSelector from "@/views/partials/customer-impact/applicationSelector.vue";
import counterTitle from "@/components/customer-impact/counterTitle.vue";
import metricSelect from "@/components/customer-impact/MetricSelect.vue";
import impactSelect from "@/components/customer-impact/ImpactSelect.vue";
import periodSelect from "@/components/customer-impact/PeriodSelect.vue";
import numbers from "@/components/customer-impact/Numbers.vue";
import dynamicFactoid from "@/components/customer-impact/DynamicFactoid.vue";
import backgrounds from "@/components/Backgrounds.vue";
import { mapGetters } from 'vuex';
import URL from "@/util/url.js";
export default {
	data : function () {
		return {

		}
	},
	mounted(){

	},
	methods : {
		showOverlay : function(key){
            this.$store.commit('setOverlayKey', key);
        },
	},
	computed : {
		...mapGetters([
			'records',
			'internalView',
			'widget',
			'impactAreas',
			'periods',
			'period',
			'impactArea',
			'metricUnits',
			'metricUnit',
		]),
		selectedImpact :function() {

			if(!this.impactAreas){
				return;
			}

			let match = _.first(_.filter(this.impactAreas, { id : this.impactArea }));

			if(!match){
			 	return _.get(_.first(this.impactAreas), "name");
			}

			return match.name;
		},
		selectedPeriod :function() {

		    if(!this.periods){
		        return;
		    }

		    let match = _.first(_.filter(this.periods, { id :this.period }));

		    if(!match){
		    	return _.get(_.first(this.periods), "name");
		    }

		    return match.name;
		},
		selectedMetric :function() {

		    if(!this.metricUnits){
		        return;
		    }

		    if(!this.metricUnit){
		        return;
		    }


		    let match = _.find(this.metricUnits, { id : this.metricUnit.id });

		    if(!match){
		    	return _.get(_.first(this.metricUnits), "name");
		    }

		    return match.name;
		},
		photoClass : function(){
			if(!this.selectedImpact){
				return;
			}
			return _.snakeCase(this.selectedImpact, '-');
		},
		currentRouteModel : function() {
		    if(!this.$route){
		        return;
		    }

		    if(!this.$route.hasOwnProperty('meta')){
		        return;
		    }

		    if(!this.$route.meta.hasOwnProperty('vuexModel')){
		        //console.error('this route is missing a vuexModel in routes/index')
		        return;
		    }
		    return `${this.$route.meta.vuexModel}`;
		},
		fullScreenLink: function() {

		    let url = window.location.protocol + "//" + window.location.host + ((window.location.pathname == '/') ? '' : window.location.pathname );
		    let hash = URL.drop(window.location.hash, 'type');

		    return `${url}/#${hash}`;
		}
	},
	components : {
		applicationSelector,
		counterTitle,
		metricSelect,
		impactSelect,
		periodSelect,
		numbers,
		dynamicFactoid,
		backgrounds
	}
}
</script>
